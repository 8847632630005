<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Emails</h4>
        </div>
        <b-card-text class="d-flex flex-column">
          <b-table
            class="lvj-table"
            :items="emailDateFormat"
            :fields="fields"
            :busy="isLoading"
            bordered
            striped
            hover
            @row-clicked="getDetails"
          >
            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                @click="getDetails(row.item)"
              >
                <b-icon-eye />
              </b-button>
            </template>
          </b-table>
          <b-button @click="getMore">
            <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
            CARREGAR MAIS
          </b-button>
        </b-card-text>
      </b-col>
    </b-row>
    <email-modal-form v-model="showModal" />
  </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EmailModalForm from "../../components/emails/EmailModalForm.vue";

export default {
  components: { EmailModalForm },
  name: "ListEmails",
  data() {
    return {
      page: 2,
      showModal: false,
      fields: [
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "assunto",
          sortable: true,
        },
        {
          key: "destino",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Data Recebido",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapState("email", ["email", "emails", "isLoading", "isLoadingSearch"]),
    emailDateFormat() {
      return this.emails.map((e) => ({
        ...e,
        created_at:
          new Date(e.created_at).toLocaleDateString("pt-BR") +
          " " +
          new Date(e.created_at).toLocaleTimeString("pt-BR"),
      }));
    },
  },
  methods: {
    ...mapActions("email", ["getAllEmails", "getOneEmail", "getMoreEmails"]),

    async getMore() {
      const { page } = this;
      await this.getMoreEmails(page);
      this.page = page + 1;
    },
    async getDetails({ id }) {
      await this.getOneEmail(id);
      this.showModal = true;
    },
  },
  async created() {
    await this.getAllEmails();
  },
};
</script>

<style></style>
