<template>
  <b-modal
    :visible="visible"
    title="Detalhes do email"
    size="lg"
    hide-footer
    @hide="close"
  >
    <b-form>
      <form-input v-model="email.nome" label="Nome" icon="person" disabled />
      <form-input v-model="email.contato" label="Contato" icon="at" disabled />
      <form-input
        v-model="email.cpf_cnpj"
        label="CPF/CNPJ"
        icon="credit-card2-front"
        disabled
      />
      <form-input
        v-model="email.assunto"
        label="Assunto"
        icon="card-heading"
        disabled
      />
      <form-textarea
        v-model="email.mensagem"
        label="Mensagem"
        rows="4"
        icon="card-text"
        disabled
      />
      <form-input
        v-model="email.destino"
        label="Destino"
        icon="envelope"
        disabled
      />
    </b-form>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import FormInput from "../form/FormInput.vue";
import FormTextarea from "../form/FormTextarea.vue";

export default {
  components: { FormInput, FormTextarea },
  name: "EmailModalForm",
  model: {
    prop: "visible",
    event: "close",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState("email", ["email"]),
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
  },
};
</script>

<style></style>
